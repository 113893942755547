
export default {
  app: {
    display: "flex",
    minHeight: "100vh",
    flexFlow: 'column wrap',
  },
  main: {
    flex: 1,
    marginTop: '90px'
  }
};
