export default {
  root: {
    flexGrow: 1,
    color: 'white'
  },
  imageProfile: {
    width: '64px;',
    height: '64px;',
    borderRadius: '32px;',
    margin: '0.5rem 0;'
  },
  menuItem: {
    textDecoration: 'none',
    color: 'white'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};
