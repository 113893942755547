import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
const { SocialIcon } = require('react-social-icons');
import styles from './styles';
class Footer extends Component {
  render() {
    const { classes } = this.props as any;

    return (
      <footer className={classes.root}>
        <div className={classes.social}>
          <SocialIcon url="https://github.com/moeslami" />
          <SocialIcon url="https://www.linkedin.com/in/mohammadeslami/" />
          <SocialIcon url="https://twitter.com/mheslami" />
        </div>
        <div className={classes.powered}>Powered by <a href="https://github.com/moeslami/sabalan" >©Sabalan</a>. All Rights Reserved</div>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
