export default {
  root: {
    backgroundColor: '#f2f2f2',
    'text-align': 'center',
    color: '#888',
    padding: '1rem',
  },
  powered: {
    'text-align': 'right'
  },
  social:{
    display: 'flex',
    '& .social-icon':{
      margin: 'auto 0.5rem'
    }
  }
}
