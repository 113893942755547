import {createMuiTheme} from '@material-ui/core/styles';
import {purple, green} from '@material-ui/core/colors';

const palette = {
  primary: { main: '#E53935' },
  secondary: { main: '#F8BBD0' }
};

const typography = {
  useNextVariants: true,
}

export default createMuiTheme({ palette,  typography });
