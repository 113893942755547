import React, { Component, ComponentClass } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Header from '../header/Header';
import routes from '../../config/routes.json';
import Footer from '../footer/Footer';
import { Helmet } from 'react-helmet';
import siteSettings from '../../config/site-settings.json';
import { resolveComponentWithData } from '../../services/component-service';
import 'typeface-roboto'
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme1 from '../../themes/theme1';
import styles from './styles';
import { Provider } from 'react-redux';
// import store from '../../redux/store';

class App extends Component {
  render() {
    const { classes } = this.props as any;

    return (
      // <Provider store={store}>

        <MuiThemeProvider theme={theme1}>
          <BrowserRouter>
            <div className={classes.app}>
              <Helmet>
                <title>{siteSettings.title}</title>
              </Helmet>
              <Header></Header>
              <main className={classes.main}>
                {Object.keys(routes).map((routePath) => {
                  const routeConfig = (routes as any)[routePath];
                  const ResolvedComponent = resolveComponentWithData(routePath, routeConfig);
                  return (<Route key={`/${routePath}`} path={`/${routePath}`} component={ResolvedComponent} exact={routeConfig.exact || false} />);
                })}
              </main>
              <Footer></Footer>
            </div>
          </BrowserRouter>
        </MuiThemeProvider>
      // </Provider>
    );
  }
}

export default withStyles(styles)(App);
